import { ArrowBack } from "@mui/icons-material";
import {
  Button,
  Stack,
  Step,
  StepLabel,
  Stepper,
  styled
} from "@mui/material";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import LoadingComponent from "../../../Core/LoadingComponent";
import styles from "./../index.module.css";

const HeaderComponent = ({ resultFile }) => {
  const AuthStateData = useSelector((state) => state.Signin);
  const dispatch = useDispatch();
  const { report_id, result_file_id } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  const userType = useSelector((state) => state.Signin.user_details?.user_type);

  const navigate = useNavigate();
  let params = location?.pathname?.split("/");
  const [loading, setLoading] = useState(false);

  function ColorlibStepIcon(props) {
    const { active, completed, className } = props;
    const icons = {
      1: (
        <Link
          to={`/grand-reports/${result_file_id}/update-report-template/${report_id}`}
        >
          <img
            src="/primaryreports/Summary.svg"
            alt=""
            height={30}
            width={30}
          />
        </Link>
      ),
      2: (
        <Link
          to={`/grand-reports/${result_file_id}/update-report-template/${report_id}/organ-specific`}
        >
          <img
            src="/primaryreports/OrganSpecific.svg"
            alt=""
            height={30}
            width={30}
          />
        </Link>
      ),
      3: (
        <Link
          to={`/grand-reports/${result_file_id}/update-report-template/${report_id}/dietary-guidelines`}
        >
          <img
            src="/primaryreports/DietaryGuidelines.svg"
            alt=""
            height={30}
            width={30}
          />
        </Link>
      ),
      4: (
        <Link
          to={`/grand-reports/${result_file_id}/update-report-template/${report_id}/weekly-diet-planner`}
        >
          <img
            src="/primaryreports/WeeklyDiet.svg"
            alt=""
            height={30}
            width={30}
          />
        </Link>
      ),
      5: (
        <Link
          to={`/grand-reports/${result_file_id}/update-report-template/${report_id}/risk-for-genetic-disorders`}
        >
          <img
            src="/primaryreports/RiskForGenericDisorder.svg"
            alt=""
            height={30}
            width={30}
          />
        </Link>
      ),
      6: (
        <Link
          to={`/grand-reports/${result_file_id}/update-report-template/${report_id}/physical-endurance`}
        >
          <img
            src="/primaryreports/physical-endurance.svg"
            alt=""
            height={30}
            width={30}
          />
        </Link>
      ),
      7: (
        <Link
          to={`/grand-reports/${result_file_id}/update-report-template/${report_id}/periodic-tests`}
        >
          <img
            src="/primaryreports/periodic-tests.svg"
            alt=""
            height={30}
            width={30}
          />
        </Link>
      ),
    };
    return (
      <ColorlibStepIconRoot
        ownerState={{ completed, active }}
        className={className}
      >
        {icons[String(props.icon)]}
      </ColorlibStepIconRoot>
    );
  }

  const steps = [
    { title: "Summary", label: "" },
    { title: "Organ-specific", label: "organ-specific" },
    { title: "Dietary Guidelines", label: "dietary-guidelines" },
    { title: "Weekly Diet Planner", label: "weekly-diet-planner" },
    {
      title: "Risk for Genetic Disorders",
      label: "risk-for-genetic-disorders",
    },
    { title: "Physical Endurance", label: "physical-endurance" },
    { title: "Periodic Tests", label: "periodic-tests" }
  ];
  const activeStep =
    steps?.findIndex((item) => item.label === params[params?.length - 1]) == -1
      ? 0
      : steps?.findIndex((item) => item.label === params[params?.length - 1]);

  const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 19,
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundImage:
          "linear-gradient(95deg, rgb(144, 238, 144) 0%, rgb(0, 128, 0) 100%)",
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundImage:
          "linear-gradient(95deg, rgb(144, 238, 144) 0%, rgb(0, 128, 0) 100%)",
      },
    },
    [`& .${stepConnectorClasses.line}`]: {
      height: 3,
      border: 0,
      backgroundColor:
        theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
      borderRadius: 1,
    },
  }));

  const goToEdit = () => {
    let categoryParam = "";

    const { pathname } = location;

    let routerObj = {
      "dietary-guidelines": "DIETARY_GUIDELINES",
      "weekly-diet-planner": "DIET_PLANNER",
    };
    const keyToCheck = pathname.split("/").at(-1);
    if (routerObj.hasOwnProperty(keyToCheck)) {
      categoryParam = routerObj[keyToCheck];
    } else {
      categoryParam = "RISK_DISORDER";
      // TODO: categoryParam = "summary";
    }

    window.open(
      `/grand-reports/${result_file_id}/update-report-template/${report_id}/preview?category=${categoryParam}`,
      "_blank"
    );
  };

  const handleLogout = async () => {
    dispatch({
      type: "SIGN_OUT",
      payload: {},
    });
    navigate("/");
  };

  const handleViewSummaryReport = async (data) => {
    setLoading(true);
    if (!data) return;

    try {
      let response = await fetch(data);
      const blob = await response.blob();
      let url = window.URL.createObjectURL(
        new Blob([blob], { type: "application/pdf" })
      );
      const printWindow = window.open(url, "_blank");
      printWindow.document.close();
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const mergeReport = async () => {
    setLoading(true);
    try {
      let url = `${process.env.REACT_APP_API_BASE_URL}/grand-report-results/${result_file_id}/reports/${report_id}/download`;
      let options = {
        method: "POST",
        headers: new Headers({
          content_Type: "application/json",
          authorization: `${AuthStateData.access_token}`,
        }),
      };
      let response = await fetch(url, options);
      let resJson = await response.json();
      if (response.status === 200 || response.status === 201) {
        setLoading(false);

        await handleViewSummaryReport(resJson.data);
      } else if (response.statusCode === 403) {
        handleLogout();
      }
    } catch (err) {
      setLoading(false);
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const backButton = () => {
    navigate("/generated-reports")
  }

  return (
    <header className={styles.header} id="editGeneratedReportPageHeader">
      <div className={styles.back}>
        <Button
          disableElevation={true}
          variant="contained"
          onClick={backButton}
        >
          <ArrowBack sx={{ color: "#000", fontSize: "1.2rem" }} />
        </Button>
        <p className={styles.text}>Back</p>
      </div>
      <div className={styles.stepper}>
        <Stack
          sx={{
            width: "100%",
            "& .MuiStepLabel-label": {
              color: "#000",
              fontFamily: "'Inter', sans-serif",
              fontSize: "clamp(10px, 0.625vw, 13px)",
              fontWeight: "500",
              marginTop: "8px",
            },

            "& .MuiStepConnector-root": {
              top: "30px",
            },
          }}
          spacing={4}
        >
          <Stepper
            alternativeLabel
            activeStep={activeStep}
            connector={<ColorlibConnector />}
          >
            {steps.map((label, index) => (
              <Step key={label.title}>
                <StepLabel StepIconComponent={ColorlibStepIcon}>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    {label.title}
                  </div>
                </StepLabel>
              </Step>
            ))}
          </Stepper>
        </Stack>
      </div>
      <div className={styles.actions}>
        {!resultFile?.is_finalized === true || userType == "DOCTOR" ? (
          <Button
            disableElevation={true}
            className="mergeBtn"
            variant="contained"
            onClick={mergeReport}
            startIcon={
              <img
                src="/actionIcon/merge-icon.svg"
                alt=""
                width={12}
                height={12}
              />
            }
          >
            Merge
          </Button>
        ) : (
          ""
        )}
      </div>
      <LoadingComponent loading={loading} />
    </header>
  );
};

export default HeaderComponent;

const ColorlibStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  backgroundColor:
    theme.palette.mode === "dark" ? theme.palette.grey[700] : "#ccc",
  zIndex: 1,
  color: "#fff",
  display: "flex",
  borderRadius: "50%",
  width: "40px",
  height: "40px",
  justifyContent: "center",
  alignItems: "center",
  ...(ownerState.active && {
    backgroundColor: "#05A155",
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  }),
  ...(ownerState.completed && {
    backgroundColor: "#05A155",
  }),
}));
